import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';
import { CATCH_KEY, DUNATI_DOMAIN } from '@/main';
import { ApiServices } from '@/services/api.services';
import { StrapiFile } from '@/class/strapiFile';

const components: any = {};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const InvisibleRecaptcha = () => import('vue-invisible-recaptcha');
  components['invisible-recaptcha'] = InvisibleRecaptcha;
}

@Component({
  components,
})
export default class FileModalComponent extends Vue {

  public data = {
    name: '',
    email: '',
    phone: '',
    message: '',
  };

  public emailSend = false;
  public loading = false;
  public sendingEmail = false;
  public errorEmail = false;
  public isSerie = true;
  public sendText = 'Enviar';
  public errorMsg = '';
  public privateKey = CATCH_KEY;
  public modalText = '';
  public serie = '';
  public downloadFile: StrapiFile | null = null;

  @Prop({ default: '' })
  public selectedOption: any;

  public mounted() {}

  public showModal(option: string, series: string, isSerie: boolean, downloadFile: StrapiFile | null = null) {
    this.serie = series;
    this.selectedOption = option;
    this.downloadFile = downloadFile;
    this.isSerie = isSerie;

    if (this.selectedOption !== '') {
      if (this.selectedOption === 'price-list') {
        this.modalText = 'Lista de precios';
      } else if (this.selectedOption === 'data-sheet') {
        this.modalText = 'Ficha técnica';
      } else if (this.selectedOption === 'dwg') {
        this.modalText = 'Bloque DWG';
      } else if (this.selectedOption === 'catalog') {
        this.modalText = 'Catálogo';
      }

      (this.$refs['modal-files'] as any).show();
    }
  }

  public Clear() {
    this.emailSend = false;
    this.sendingEmail = false;
    this.errorEmail = false;
    this.data = {
      name: '',
      email: '',
      phone: '',
      message: '',
    };
  }

  public Close(modal: string) {
    this.Clear();
    this.$root.$emit('bv::hide::modal', modal);
  }

  public titleCase(str: string) {
    const splitStr = str.toLowerCase().split(' ');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  public callbackTest(recaptchaToken: any) {
    if (recaptchaToken) {
      this.sendMail();
    }
  }

  public sendMail() {
    if (this.data.name !== '' && this.data.message !== '' && this.data.email !== '' && this.validEmail(this.data.email)) {
      this.sendingEmail = true;
      this.loading = true;
      let file = '';

      if (this.selectedOption === 'price-list') {
        file = 'Lista de precios';
      } else if (this.selectedOption === 'data-sheet') {
        file = 'Ficha técnica';
      } else if (this.selectedOption === 'dwg') {
        file = 'Bloque DWG';
      } else if (this.selectedOption === 'catalog') {
        file = 'Catálogo';
      }

      let conversionUrl = '';
      if (typeof window !== 'undefined') {
        conversionUrl = window.entryUrl;
      }

      let serieHtml = '';
      let requestSerie = '';
      if (this.isSerie) {
        serieHtml = `Link de la serie: <a href="${DUNATI_DOMAIN}/escritorios/${this.serie}">Serie ${this.serie}</a>`;
        requestSerie = `\nSerie solicitada: Serie ${this.serie}`;
      } else {
        serieHtml = `Link del producto: <a href="${conversionUrl}">${this.serie}</a>`;
        requestSerie = `\nProducto solicitado: ${this.serie}`;
      }

      const msg = `<h3>Solicitud de archivos</h3>
      <p>De: ${this.titleCase(this.data.name)}</p>
      <p>Correo: ${this.data.email}</p>
      <p>Teléfono: ${this.data.phone || 'NA'}</p>
      <p>Archivo solicitado: ${file}</p>
      <p>${serieHtml}</p>
      <br>
      <p>${this.data.message}</p>`;

      const body = {
        notificationsFrom: `${this.titleCase(this.data.name)}`,
        msg,
        subject: `Solicitud de archivos, petición de: ${this.titleCase(this.data.name)}`,
        contact: {
          email: this.data.email,
          phone: this.data.phone,
          message: this.data.message,
          name: this.titleCase(this.data.name),
          source: `escritorios/${this.serie}`,
          conversionUrl,
          extras: {
            serie: requestSerie,
            file: `\nArchivo solicitado: ${file}`,
          },
        },
      };

      ApiServices.sendMail(body).then((res) => {
        if (res.data) {
          this.sendText = '¡Listo!';
          this.emailSend = true;

          if (this.downloadFile) {
            this.downloadURI(this.downloadFile)
          }

          this.sendingEmail = false;
          this.loading = false;

          setTimeout(() => {
            this.Close('modal-files');
          }, 10000);
        }
      });
    } else {
      this.errorEmail = true;
      if (!this.validEmail(this.data.email)) {
        this.errorMsg = 'El correo electrónico debe ser válido.';
      } else if (this.data.name === '') {
        this.errorMsg = 'El campo nombre es requerido.';
      } else if (this.data.email === '') {
        this.errorMsg = 'El campo correo es requerido.';
      } else if (this.data.message === '') {
        this.errorMsg = 'El campo mensaje es requerido.';
      }

      setTimeout(() => {
        this.errorEmail = false;
        this.errorMsg = '';
      }, 20000);
    }
  }

  public validEmail(email: string) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  public downloadURI(file: StrapiFile): void {
    let xhttp = new XMLHttpRequest();
    xhttp.onreadystatechange = function() {
    if (this.readyState == 4 && this.status == 200) {
        let downloadUrl = URL.createObjectURL(xhttp.response);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.style.display = "none";
        a.href = downloadUrl;
        a.download = file.name;
        a.click();
      }
    };

    xhttp.open("GET", file.url, true);
    xhttp.responseType = "blob";
    xhttp.send();
  }
}
