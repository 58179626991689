// tslint:disable-next-line: no-var-requires
require('vue-image-lightbox/dist/vue-image-lightbox.min.css');
import Vue from 'vue';
import Component from 'vue-class-component';
import DunatiLoaderComponent from '@/components/dunati-loader/dunati-loader.component';
import FileModalComponent from '@/components/file-modal/file-modal.component';
import SeriesSectionCollageComponent from '@/components/series-section-collage/series-section-collage.component';
import { DUNATI_DOMAIN, DUNATI_ORGANIZATION_SCHEMA, ROBOT_CONTENT } from '@/main';

const components: any = {
  DunatiLoaderComponent,
  FileModalComponent,
  SeriesSectionCollageComponent
};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const LightBox = () => import('vue-image-lightbox');
  // @ts-ignore
  const carousel = () => import('vue-owl-carousel');
  components.carousel = carousel;
  components.LightBox = LightBox;
}

@Component({
  components,
})
export default class SerieTrainingPage extends Vue {

  public scrollable = true;
  public autoplay = true;
  public overlayColor = "#000000";
  public  overlayTransparency = 50;
  public picturesViewer: 'carousel' | 'collage' = 'carousel';

  public carouselResponsive = {
    320: {
      items: 1,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: this.autoplay,
      autoplayHoverPause: true
    },
    767: {
      items: 2,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: this.autoplay,
      autoplayHoverPause: true
    },
    992: {
      items: 3,
      nav: true,
      autoHeight: true,
      mouseDrag: false,
      touchDrag: true,
      autoplay: false
    },
    1140: {
      items: 4,
      nav: true,
      autoplay: false
    }
  };
  public listCarouse = [
    { img: 'Mesas-Grupales-Training.jpg' },
    { img: 'Mesa-de-capacitación-Serie-Training.jpg' },
    { img: 'Mesas-Training-Apiladas.jpg' },
    { img: 'Mesa-Training.jpg' },
    { img: 'Mesa-Training-Apilada.jpg' },
    { img: 'Mesa-Training-con faldon.jpg' }
  ];
  public images = [
    { src: '/assets/images/series/serie_training/Mesas-Grupales-Training.jpg' },
    { src: '/assets/images/series/serie_training/Mesa-de-capacitación-Serie-Training.jpg' },
    { src: '/assets/images/series/serie_training/Mesas-Training-Apiladas.jpg' },
    { src: '/assets/images/series/serie_training/Mesa-Training.jpg' },
    { src: '/assets/images/series/serie_training/Mesa-Training-Apilada.jpg' },
    { src: '/assets/images/series/serie_training/Mesa-Training-con faldon.jpg' }
  ];

  public collageBackground = null;
  public collage  = [{
      src: '/assets/images/series/serie_training/Mesa-Training-con faldon.jpg',
      small: '/assets/images/series/serie_training/Mesa-Training-con faldon.jpg',
      width: 1440,
      height: 974,
      smallWidth: 1440,
      smallHeight: 974,
      alt: 'Mesa Training con faldon'
  }, {
      src: '/assets/images/series/serie_training/Mesa-de-capacitación-Serie-Training.jpg',
      small: '/assets/images/series/serie_training/Mesa-de-capacitación-Serie-Training.jpg',
      width: 2640,
      height: 1485,
      smallWidth: 2640,
      smallHeight: 1485,
      alt: 'Mesa de capacitación Serie Training'
  }];

  constructor() {
    super();
    this.$options.dunatiHeadTags = {
      title: 'Escritorios Serie Training - Escritorios y Muebles de oficina | Dunati',
      metaTags: [
        /* Primaty Tags */
        {
          name: 'description',
          content: 'Mesas modulares, móviles y versátiles.',
        },
        {
          name: 'robot',
          content: ROBOT_CONTENT,
        },
        /* Open Graph / Facebook */
        {
          property: 'og:type',
          content: 'website',
        },
        {
          property: 'og:url',
          content: `${DUNATI_DOMAIN}/escritorios/training`,
        },
        {
          property: 'og:title',
          content: 'Escritorios Serie Training - Muebles de oficina | Dunati',
        },
        {
          property: 'og:description',
          content: 'Distingue por su funcionalidad, gran calidad y capacidad de adaptación. Ideal para espacios de alta carga de ocupación. Training ofrece muebles de oficina modulares y ajustables en distintas configuraciones.',
        },
        {
          property: 'og:image',
          content: `${DUNATI_DOMAIN}/assets/images/home_page/og_home.jpg`,
        },
        /* Twitter */
        {
          property: 'twitter:card',
          content: 'summary_large_image',
        },
        {
          property: 'twitter:url',
          content: `${DUNATI_DOMAIN}/escritorios/training`,
        },
      ],
      /*
      metaInfo: {
        link: [
          {
            rel: 'canonical',
            href: `${DUNATI_DOMAIN}/escritorios/training`
          }
        ]
      }
      */
    };


    this.$options.dunatiJsonld = [
      DUNATI_ORGANIZATION_SCHEMA,
      {
        "@context": "http://schema.org",
        "@type": "BreadcrumbList",
        "itemListElement": [
          {
            "@type": "ListItem",
            "position": 1,
            "item": {
                "@id": DUNATI_DOMAIN,
                "name": "Inicio"
            }
          },
          {
            "@type": "ListItem",
            "position": 2,
            "item": {
                "@id": `${DUNATI_DOMAIN}/productos`,
                "name": "Productos"
            }
          },
          {
            "@type": "ListItem",
            "position": 3,
            "item": {
              "@id": `${DUNATI_DOMAIN}/escritorios`,
              "name": "Escritorios"
            }
          },
          {
            "@type": "ListItem",
            "position": 4,
            "item": {
              "@id": `${DUNATI_DOMAIN}/escritorios/training`,
              "name": "Training"
            }
          }
        ]
      }
    ];
  }

  public mounted() {
    window.addEventListener('touchmove', this.preventBehavior, { passive: false });
  }

  public destroyed() {
    window.removeEventListener('touchmove', this.preventBehavior);
  }

  public updated() {
    this.$nextTick(() => {
      $('#carouselImg .owl-carousel .owl-item img').click((el) => {
        this.showImgSlider((el.target.dataset.index) ? +el.target.dataset.index : 0);
      });

      const owl = $('#carouselImg .owl-carousel');

      owl.on('drag.owl.carousel', (event) => {
        document.ontouchmove = (e) => {
          e.preventDefault();
        };
      });

      owl.on('dragged.owl.carousel', (event) => {
        document.ontouchmove = (e) => {
          return true;
        };
      });

    });
  }

  public showImgSlider(index: number) {
    if (typeof (index) === 'number') {
      // @ts-ignore
      if (this.$mq === 'sm' || this.$mq === 'xs') {
        return;
      }

      // @ts-ignore
      this.$refs.lightBox.showImage(index);
    }
  }

  public dragged() {
    $('body').css('overflow', 'auto');
    this.scrollable = true;
  }

  public drag() {
    if (window.innerWidth <= 768) {
      $('body').css('overflow', 'hidden');
    }
    this.scrollable = false;
  }

  public preventBehavior(e: any) {
    if (!this.scrollable) {
      e.preventDefault();
    }
  }

  public onLoaded(id: string) {
    const cls: string = `.${id}`;
    $(cls).hide();
  }

  public setText(option: string) {
    (this.$refs['file-modal'] as any).showModal(option, 'training', true);
  }
  
  public hex2rgba(hex: string, transparency: number = 100): string {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    const alpha = 1 - (transparency/100);
    
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  };
}
