import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

const auxComponent: any = {};

if (typeof window !== 'undefined') {
  // @ts-ignore
  const LightBox = () => import('vue-image-lightbox');
  auxComponent.LightBox = LightBox;
}
 
@Component({
  components: auxComponent,
})
export default class SeriesSectionCollageComponent extends Vue {
  @Prop({ default: [] })
  // @ts-ignore
  public images: { 
    src: string;
    small: string;
    width: number;
    height: number;
    smallWidth: number;
    smallHeight: number;
    alt: 'Mesa Training con faldon'
  }[];

  @Prop({ default: '#fff' })
  // @ts-ignore
  public background: string;

  public isMobile: boolean = false;
  public lightBoxRef: string = '';
  public auxKey!: number;

  public mounted(): void {
    this.auxKey = this.randomKey();
    this.lightBoxRef = `collageLightBox${this.auxKey}`;

    this.getWidth();
    window.addEventListener('resize', this.getWidth);
  }

  public destroyed(): void {
    window.removeEventListener('resize', this.getWidth);
  }

  paddingBottom(picture: any): object {
    let width = picture.width;
    let height = picture.height

    if (this.isMobile) {
      width = picture.smallWidth;
      height = picture.smallHeight
    }


   return { "padding-bottom": `${height / width * 100.0}%` };
  }

  flexBasis(picture: any): object {
    let width = picture.width;
    let height = picture.height
    let baseValue = 240;

    if (this.isMobile) {
      baseValue = 100;
      width = picture.smallWidth;
      height = picture.smallHeight
    }

    return { 'flex-grow': `${width * 100 / height}`, 'flex-basis': `${width * baseValue / height}px` }
  }

  getWidth(): void {
    const query: string = '(max-width: 767.98px)';
    const mediaQueryList: any = window.matchMedia(query);

    if (mediaQueryList && mediaQueryList.matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
  }

  showImgSlider(index: number): void {
    if (typeof (index) === 'number') {
      // @ts-ignore
      this.$refs[`${this.lightBoxRef}`].showImage(index);
    }
  }

  randomKey(): number {
    return Math.floor(Math.random() * 10000);
  }
}

